<template>
  <div
    v-if="isVisible"
    :style="baseStyle"
  >
    <span v-for="msg in message.messages">{{ msg }}</span>
  </div>
</template>
<script>
export default {
  name: 'MessageLine',

  props: {
    message: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      currentTime: null,
      interval: null,
      life: 5000, // 4 seconds at full visibility
      decayLength: 1000 // 1 second of decay
    }
  },

  computed: {

    timestamp () {
      return this.message.ts
    },

    isVisible () {
      return this.visibilityLevel > 0
    },

    visibilityLevel () {
      let displayTime = this.currentTime - this.timestamp
      let v = displayTime - this.life

      if (v < 0) return 100
      if (v < this.decayLength) {
        return Math.floor((1 - (v / this.decayLength)) * 100)
      }
      return 0
    },

    baseStyle () {
      return 'opacity:' + this.visibilityLevel + '%'
    }
  },

  watch: {
    timestamp () {
      this.startTimer()
    },

    isVisible: {
      handler () {
        if (this.isVisible) this.startTimer()
        else this.endTimer()
      },
      immediate: true
    }
  },

  mounted () {
    this.currentTime = Date.now()
    this.startTimer()
  },

  beforeDestroy () {
    this.endTimer()
  },

  methods: {
    startTimer () {
      this.interval = setInterval(function () {
        this.currentTime = Date.now()
      }.bind(this), 100)
    },

    endTimer () {
      clearInterval(this.interval)
      this.interval = null
    }
  }
}
</script>
